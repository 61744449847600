import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FAQsSection from "./faqs";

export default function MicrorecLowerpart(selectedProduct) {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ paddingTop: "0px" }}>
        <Typography
          variant="body1"
          paragraph={true}
          gutterBottom={true}
          sx={{ textAlign: "right", fontSize: "18px", padding: "10px" }}
        >
          Need help?{" "}
          <a
            className="link_contact"
            href="/contact-us"
            target="_blank"
            style={{ color: "#8F4FFF", fontWeight: "bold" }}
          >
            Contact us
          </a>
        </Typography>
        <hr></hr>
      </Grid>

      <Grid
        container
        sx={{ marginTop: "2em" }}
        maxWidth={"lg"}
        style={{ paddingLeft: "20px" }}
      >
        <Grid item xs={12} sm={4}>
          <Typography
            variant={"h5"}
            color={"primary"}
            sx={{ paddingBottom: 2, fontWeight: "bold" }}
          >
            Hardware Specifications
          </Typography>
        </Grid>
        {selectedProduct.selectedProduct === "Beam Splitter type Z + CSO" ? (
          <Grid
            item
            xs={11.5}
            sm={7.5}
            sx={{
              border: "1px solid black",
              borderRadius: "10px",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <Grid container>
              <Grid xs={3}>
                <div style={{ fontWeight: "600" }}>Weight BS</div>
                <div>170g</div>
              </Grid>
              <Grid xs={3}>
                <div style={{ fontWeight: "600" }}>Width BS</div>
                <div>160mm</div>
              </Grid>
              <Grid xs={3}>
                <div style={{ fontWeight: "600" }}>Length BS</div>
                <div>110mm</div>
              </Grid>
              <Grid xs={3}>
                <div style={{ fontWeight: "600" }}>Depth BS</div>
                <div>60mg</div>
              </Grid>
            </Grid>
            <Grid container sx={{ marginTop: "2em" }}>
              <Grid xs={3}>
                <div style={{ fontWeight: "600" }}>Weight CSO</div>
                <div>70mm</div>
              </Grid>
              <Grid xs={3}>
                <div style={{ fontWeight: "600" }}>Width CSO</div>
                <div>70mm</div>
              </Grid>
              <Grid xs={3}>
                <div style={{ fontWeight: "600" }}>Length CSO</div>
                <div>70mm</div>
              </Grid>
              <Grid xs={3}>
                <div style={{ fontWeight: "600" }}>Depth CSO</div>
                <div>45mm</div>
              </Grid>{" "}
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            xs={11.5}
            sm={7.5}
            sx={{
              border: "1px solid black",
              borderRadius: "10px",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <Grid container>
              <Grid xs={6}>
                <div style={{ fontWeight: "600" }}>Weight</div>
                <div>170g</div>
              </Grid>
              <Grid xs={6}>
                <div style={{ fontWeight: "600" }}>Length</div>
                <div>110mm</div>
              </Grid>
            </Grid>
            <Grid container sx={{ marginTop: "2em" }}>
              <Grid xs={6}>
                <div style={{ fontWeight: "600" }}>Width</div>
                <div>160mm</div>
              </Grid>
              <Grid xs={6}>
                <div style={{ fontWeight: "600" }}>Depth</div>
                <div>60mm</div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <FAQsSection />
    </Grid>
  );
}
